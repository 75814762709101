<template>
  <main id="main">
    <div class="container">
      <section class="contact-clean">
        <form
          data-bss-recipient="770433cc70798ba725cf7791b5625140"
          data-bss-subject="Token Request"
        >
          <h2 class="text-center">Contact us</h2>
          <div class="mb-3">
            <input
              class="form-control"
              type="text"
              name="name"
              placeholder="Name"
            />
          </div>
          <div class="mb-3">
            <input
              class="form-control"
              type="email"
              name="email"
              placeholder="Email"
            />
          </div>
          <div class="mb-3">
            <textarea
              class="form-control"
              name="message"
              placeholder="Message"
              rows="14"
            ></textarea>
          </div>
          <div class="d-flex justify-content-center" style="margin-top: 20px">
            <button class="btn btn-primary" type="submit">send</button>
          </div>
        </form>
      </section>
      <div class="text-center" style="margin-top: 15px">
       

        <ins
          class="adsbygoogle"
          style="display: block"
          data-ad-client="ca-pub-3954559686988335"
          data-ad-slot="1039333824"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      
      </div>
    </div>
  </main>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  setup() {
    
  },
})
</script>

<style scoped>

.container, [dir=ltr] .container-fluid, [dir=ltr] .container-xxl, [dir=ltr] .container-xl, [dir=ltr] .container-lg, [dir=ltr] .container-md, [dir=ltr] .container-sm {
    padding-right: 1rem;
    padding-left: 1rem;
    margin-right: auto;
    margin-left: auto;
}

#main {
    margin-bottom: 50px;
    margin-top: 103px;
    padding-left: calc(100vw - 80%);
    padding-right: calc(100vw - 80%);
    padding-bottom: calc(100vh - 100%);
}
*, ::after, ::before {
    box-sizing: border-box;
}
user agent stylesheet
main {
    display: block;
}
body {
    background-color: #181a1b;
    color: #fff;
    height: 100%;
    overflow-x: hidden;
    width: 100vw!important;
}
.dark-layout textarea.form-control:not(:focus) {
    border-color: #404656;
    max-height: 100px;
}
body {
    margin: 0;
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    background-color: var(--bs-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}
:root {
    --swiper-navigation-size: 44px;
}
:root {
    --swiper-theme-color: #007aff;
}
:root {
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: #0d6efd;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-primary-rgb: 13,110,253;
    --bs-secondary-rgb: 108,117,125;
    --bs-success-rgb: 25,135,84;
    --bs-info-rgb: 13,202,240;
    --bs-warning-rgb: 255,193,7;
    --bs-danger-rgb: 220,53,69;
    --bs-light-rgb: 248,249,250;
    --bs-dark-rgb: 33,37,41;
    --bs-white-rgb: 255,255,255;
    --bs-black-rgb: 0,0,0;
    --bs-body-rgb: 33,37,41;
    --bs-font-sans-serif: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #212529;
    --bs-body-bg: #fff;
}
*, ::after, ::before {
    box-sizing: border-box;
}
*, ::after, ::before {
    box-sizing: border-box;
}
::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-thumb {
    background: #888;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
</style>
